import App from './App.js'
import splash from './views/partials/splash'
import {html, render } from 'lit-html'

//components
import './components/main-app-header'
import './components/c-error'
import './components/c-success'
import './components/c-waiting'

// //app widget components  
import './components/apps/widget-icon.js'
import './components/apps/app-calculator.js'
// import './components/apps/app-image-text.js'
import './components/apps/app-dark-theme.js'
import './components/apps/app-date-picker.js'



// not for general viewing
// import './components/apps/app-left-right.js'


//styles
import './scss/master.scss'



// render(splash, App.rootEl)

document.addEventListener('DOMContentLoaded', () => {
  App.init()
})

window.addEventListener("resize", () => {
  App.resizeListener()
})
