
class DatePicker {

  constructor(){
    this.d = new Date()
    this.currentYear = this.d.getFullYear()
    this.currentMonth = this.d.getMonth()
    this.currentDay = this.d.getDate() //not yet used
    this.langCode = this.localLang()
  }

  getDisplayDate(longdate){
    const dateString = longdate.toString()
    const displayDate = dateString.slice(0,15)
    return displayDate
  }

  getMyLanguage(lang){ 
    const languageNames = new Intl.DisplayNames([lang], { type: 'language' });
    return languageNames.of(lang)  
  }

  localLang(){
    const mylanguage = window.navigator.userLanguage || window.navigator.language; // or navigator.language; //output en-GB
    return mylanguage.slice(0,2); //1st 2 characters (otherwise country brackets included) 
  }

  getCurrentDays(year, month) {
    let date = new Date(year, month, 1);
    let array = []
    while (date.getMonth() == month) {
      let mainDateItem = {}
      mainDateItem.longdate = new Date(date)
      mainDateItem.dateId = mainDateItem.longdate.getDate()
      array.push(mainDateItem);
      date.setDate(date.getDate() + 1); //move to next day
    }
    return array
  }

  getPreviousDays(year, month){
    let date = new Date(year, month, 1)
    let preDaysArray = []
    const firstday = date.getDay()
    if(firstday > 0 ){
      let x = firstday - 1
      let dateItem = {}
      date.setMonth(date.getMonth(), 0);
      dateItem.dateId = date.getDate()
      preDaysArray.push(dateItem);
      while(x>0){
        dateItem = {}
        date.setDate(date.getDate() -1)
        dateItem.dateId = date.getDate()
        preDaysArray.unshift(dateItem);
        x--
      }
      return preDaysArray
    } else { return false }
  }

  getNextDays(array){
    const index = array.length-1
    let nextDaysArray = []
    const lastday = array[index].longdate.getDay()
    if(lastday < 6 ){
      let x = 6 - lastday
      let y = 1
      while(x>0){
        let dateItem = {}
        dateItem.dateId = y
        nextDaysArray.push(dateItem);
        y++
        x--
      }
      return nextDaysArray
    } else { return false }
  }
  
}

export default new DatePicker()