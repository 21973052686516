import {LitElement, html, render} from 'lit'
import languages from '../../data/browser-locale.json'
import DatePicker from '../../components/apps/DatePicker'
import Element from '../../Element'


customElements.define('app-date-picker', class Features extends LitElement {
  constructor(){
    super()    
  } 
    
  firstUpdated(){
    super.firstUpdated()
    this.d = new Date()
    this.optionDiv = this.shadowRoot.getElementById("options")
    this.resultsDiv = this.shadowRoot.getElementById("results")

    this.newDate = DatePicker.d
    this.lastDayYear = this.newDate.setMonth(this.newDate.getMonth(), 0);
    this.myLang = DatePicker.langCode
    this.myYear = DatePicker.currentYear
    this.myMonth = DatePicker.currentMonth

    this.insertYear(DatePicker.currentYear) 
    this.insertMonth(DatePicker.currentMonth, DatePicker.langCode) 
    this.daysForLocale(DatePicker.langCode, 'short') 
    this.populateCalendar(DatePicker.currentYear, DatePicker.currentMonth)

    this.durationOpt = false
    this.startDate = null
    this.endDate = null
  }
  // <!-- NOTE: Sunday is set as 1st day -->

  // const newDate = {
  //   year: this.myYear,
  //   month: this.myMonth,
  //   day: dateId,
  //   date: dateId + '/' + this.myMonth + '/' + this.myYear
  // }


  appendDateEl(dayN, classes, otherStatus){
    const thisCalendar = this.shadowRoot.getElementById('day-numbers')
    const newEl = document.createElement('div')
    let dateP
    if (otherStatus === true) {
      dateP = html`<div class="${classes}"><p>${dayN}</p></div>`
    } else {
      dateP = html`<div data-id="${dayN}" class="${classes}" @click="${this.selectDate.bind(this)}"><p>${dayN}</p></div>`
    }
    render(dateP, newEl)
    thisCalendar.appendChild(newEl)
  }

  populateCalendar(year, month){
    const calendar = this.shadowRoot.getElementById('day-numbers')
    Element.removeAllChildren(calendar)
    const today = new Date()
    //get arrays
    const mainDays = DatePicker.getCurrentDays(year, month)
    const preDays = DatePicker.getPreviousDays(year, month)
    const nextDays = DatePicker.getNextDays(mainDays)
    //populate the calendar
    if (!preDays === false){
      preDays.forEach(day =>{ this.appendDateEl(day.dateId, "other-days prev-month", true) })
    }
    mainDays.forEach(day => {
      if(today.getFullYear() == this.myYear && today.getMonth() == this.myMonth && today.getDate() == day.longdate.getDate()){
        this.appendDateEl(day.dateId, "these-days today") } 
      else { this.appendDateEl(day.dateId, "these-days") }
    })
    if (!nextDays === false){
      nextDays.forEach(day => { this.appendDateEl(day.dateId, "other-days next-month", true) })
    }
    //set selected styles if any
    if(this.startDate && this.endDate){this.getDurationStatus()} 
    else if ((this.startDate) && (this.startDate.year == this.myYear) && (this.startDate.month == this.myMonth )) { 
      this.setSelectStyle(this.startDate.day) 
    } 
    else if ((this.endDate) && (this.endDate.year == this.myYear) && (this.endDate.month == this.myMonth )) { 
      this.setSelectStyle(this.endDate.day) 
    } 
    return true
  }//END OF POPULATE CALENDAR FUNCTION

  selectDate(e){
    const datePeriodEl = this.shadowRoot.querySelector(".result-active")
    const dateId =  e.target.closest('div').getAttribute("data-id")
    const newDate = {
      year: Number(this.myYear),
      month: Number(this.myMonth),
      day: Number(dateId),
      date: dateId + '/' + (this.myMonth+1) + '/' + this.myYear
    }
    switch(datePeriodEl.getAttribute('id')){
      case 'result1':
      //start date logic
        if( this.startDate && newDate.date == this.startDate.date){ return }
        if (this.startDate) {
          this.removeSelectStyle(this.startDate.day)
        }
        e.target.closest('div').classList.add('date-selected')
        this.startDate = newDate
        this.updateDisplay(newDate.date, 'result1')
          if(this.endDate ){ 
            this.validateDateOrder()
            this.getDurationStatus() 
          } 
      break;
      case 'result2':
      //end date logic
        if(this.endDate && newDate.date == this.endDate.date){ return }
        if (this.endDate) {
          this.removeSelectStyle(this.endDate.day)
        }
        e.target.closest('div').classList.add('date-selected')
        this.endDate = newDate
        this.updateDisplay(newDate.date, 'result2')
        if(this.startDate){ 
          this.validateDateOrder()
          this.getDurationStatus() 
        }
      break;
      default: return
    }
  }

  removeSelectStyle(id){
    let div = this.shadowRoot.querySelector(`[data-id='${id}']`) 
    if(div.classList.contains('today')){ 
      div.className = 'these-days'
      div.classList.add('today')
     } else {
      div.className = 'these-days'
     }
  }

  //end or start - pass in ID -via populate calendar & via setDuration range.  
  //pos - 'start' or 'end'
  setSelectStyle(id, pos){
    let prevSelection = this.shadowRoot.querySelector(`[data-id='${id}']`)
    prevSelection.classList.add('date-selected')
    if(pos == 'start'){ prevSelection.classList.add('start-range') }
    if(pos == 'end'){ prevSelection.classList.add('end-range') }
  }

  updateDisplay(text, divId, duration){
    let elType = 'span'
    if(duration === true){elType = 'p'}
    const oldDisplay1 = this.shadowRoot.querySelector(`#${divId} ${elType}`)
    const newSpan1 = Element.createNewElement(elType)
    const newDisplay1 = Element.appendTextNodes(newSpan1, text)
    oldDisplay1.replaceWith(newDisplay1)
  }

  validateDateOrder(){
  //test the dates are in a valid order (otherwise switch them)
    let rangeStart = this.startDate
    let rangeEnd = this.endDate
    if((rangeStart.year > rangeEnd.year) || (rangeStart.year == rangeEnd.year && rangeStart.month > rangeEnd.month) || (rangeStart.year == rangeEnd.year && rangeStart.month == rangeEnd.month && rangeStart.day > rangeEnd.day) ) {
      this.endDate = rangeStart 
      this.startDate = rangeEnd 
      this.updateDisplay(rangeEnd.date, 'result1')
      this.updateDisplay(rangeStart.date, 'result2')
    }
  }

  //getDurationStatus(type)  start, end, duration - if empty, run test
  getDurationStatus(){   
    const startPos = this.getRangePostion(this.startDate)
    const endPos = this.getRangePostion(this.endDate)
    if(startPos == 'after' || endPos == 'before'){ console.log('out of range'); return}
    if(startPos == 'current' && endPos == 'current' ){ this.setDurationStyles('in') }
    if(startPos == 'current' && endPos == 'after' ){ this.setDurationStyles('post') }
    if(startPos == 'before' && endPos == 'current' ){ this.setDurationStyles('pre') }
    if(startPos == 'before' && endPos == 'after' ){ this.setDurationStyles('over') }
  }

  getRangePostion(dateObj){
    if(dateObj.year == this.myYear && dateObj.month == this.myMonth){ return 'current'}
    else if(dateObj.year < this.myYear || dateObj.year == this.myYear && dateObj.month < this.myMonth){ return 'before'}
    else if(dateObj.year > this.myYear || dateObj.year == this.myYear && dateObj.month > this.myMonth){ return 'after'}
  }

  //if range dates are not within this.myMonth this.myYear - then don't call this function
  setDurationStyles(rangeStatus){
    const prevDivs = this.shadowRoot.querySelectorAll('.mid-range')
    const endRange = this.shadowRoot.querySelectorAll('.end-range')
    const starteRange = this.shadowRoot.querySelectorAll('.start-range')
    prevDivs.forEach(div => { div.classList.remove('mid-range') })
    endRange.forEach(div => { div.classList.remove('end-range') })
    starteRange.forEach(div => { div.classList.remove('start-range') })
    let midArray = []
    let startNo = 1
    let endNo = new Date(this.myYear, this.myMonth + 1, 0).getDate() + 1;
    switch (rangeStatus) {
      // case 'over':
      //     all elements
      //     break;
      case 'in':
        startNo = Number(this.startDate.day) + 1
        endNo =  Number(this.endDate.day)
        if(this.startDate.day == this.endDate.day){
          this.setSelectStyle(this.startDate.day) //'end' or 'start'
          // this.setSelectStyle(this.endDate.day, 'end') //'end' or 'start'
        } else {
          this.setSelectStyle(this.startDate.day, 'start', true) //'end' or 'start'
          this.setSelectStyle(this.endDate.day, 'end') //'end' or 'start'
        }
          break;
      case 'pre':
          endNo = Number(this.endDate.day)
          this.setSelectStyle(this.endDate.day, 'end') //'end' or 'start'
          break;
      case 'post':
          startNo = Number(this.startDate.day) + 1
          this.setSelectStyle(this.startDate.day, 'start') //'end' or 'start'
          break;
      default: break;
    }
    for (var i = startNo; i < endNo; i++) { midArray.push(i); }
    midArray.forEach((day) => {
      const setDiv = this.shadowRoot.querySelector(`[data-id='${day}']`)
      setDiv.classList.add('mid-range')
    })
  }


  //WEEKDAY NAMES - gets & inserts days
  daysForLocale(localeName = 'ar', weekday = 'short') { // 'en-GB', 'short'
    const daysDiv =  this.shadowRoot.getElementById("weekdays") 
    Element.removeAllChildren(daysDiv)
    //get days in locale or user language and print in calendar
    const {format} = new Intl.DateTimeFormat(localeName, { weekday });
    const days = [...Array(7).keys()]
      .map((day) => format(new Date(Date.UTC(2021, 5, day-1))));
      days.forEach((day)=>{
        const newDay = Element.createNewElement('div', day, 'day-name')
        const newDayP = Element.createNewElement('p')
        const textP = Element.appendTextNodes(newDayP, day)
        newDay.appendChild(textP)
        daysDiv.appendChild(newDay)
      })   
  }

  //UPDATE MONTH FUNCTIONS
  insertMonth(month, lang){
    const monthDiv = this.shadowRoot.getElementById("selected-month")
    const ourMonth = this.getThisMonth(month, lang)
    Element.replaceTextItem(monthDiv, ourMonth)
  }

  getThisMonth(idx, locale){
    const objDate = new Date()
    objDate.setDate(1)
    objDate.setMonth(idx) 
    const month = objDate.toLocaleString(locale, { month: "long" });
    return month
  }

  //These following three functions could be merged? or reduced to 2
  prevMonth(){
    let newMonth = this.myMonth - 1
    if(newMonth == -1){ 
      newMonth = 11 
      this.myYear = Number(this.myYear) - 1 
      this.insertYear(this.myYear) 
    } 
    this.insertMonth(newMonth, this.myLang)
    this.myMonth = newMonth
    this.populateCalendar(this.myYear, newMonth)
  }

  nextMonth(){
    let newMonth = this.myMonth + 1
    if(newMonth == 12){ 
      newMonth = 0; 
      this.myYear = Number(this.myYear) + 1
      this.insertYear(this.myYear) 
    } 
    this.insertMonth(newMonth, this.myLang)
    this.myMonth = newMonth
    this.populateCalendar(this.myYear, newMonth)
  }

  monthSelect(e){
    const newMonth =  e.target.getAttribute("id")
    if( this.myMonth !== newMonth ){
      this.myMonth =  Number(newMonth)
      this.insertMonth(newMonth, this.myLang)
      this.populateCalendar(this.myYear, newMonth)
    }
    this.closeOptions()
  }

  showMonthRange(){
    const months = [...Array(12).keys()]
    Element.removeAllChildren(this.optionDiv)
    this.optionDiv.classList.remove('hidden')
    let addDiv = ''
    months.forEach((month, index)=>{
      let localMonth = this.getThisMonth(month, this.myLang)
      if(month == DatePicker.currentMonth){
        if (DatePicker.currentMonth == this.myMonth){
          addDiv = html`<div id="${index}" class="option-item option-selected option-current" @click="${this.monthSelect.bind(this)}">${localMonth}</div>`
        } else {
          addDiv = html`<div id="${index}" class="option-item option-current" @click="${this.monthSelect.bind(this)}">${localMonth}</div>`
        }
      } else {
        if(month == this.myMonth){
          addDiv = html`<div id="${index}" class="option-item option-selected" @click="${this.monthSelect.bind(this)}">${localMonth}</div>`
        } else {
          addDiv = html`<div id="${index}" class="option-item" @click="${this.monthSelect.bind(this)}">${localMonth}</div>`
        }
      }
      const dialogEL = document.createElement('ps-month')
      render(addDiv, dialogEL)
      this.optionDiv.append(dialogEL) 
    }) 
    this.optionDiv.scrollTo(0, 0)
  }


  insertYear(year){
    const yearDiv = this.shadowRoot.getElementById("selected-year")
    Element.replaceTextItem(yearDiv, year)
  }


  yearSelect(e){
    const newYear =  e.target.innerText
    if(this.myYear !== newYear ){
      this.myYear = newYear
      this.insertYear(newYear) 
      this.populateCalendar(this.myYear, this.myMonth) 
    }
    this.closeOptions()
  }

  showYearRange(){
    let yearArray = []
    yearArray.push(DatePicker.currentYear + 1)
    for (let  x = DatePicker.currentYear; x > DatePicker.currentYear - 10; x-- ) {
      yearArray.push(x)
    }
    Element.removeAllChildren(this.optionDiv)
    this.optionDiv.classList.remove('hidden')
    let addDiv = ''
    yearArray.forEach(year => { 
      if(year == DatePicker.currentYear){
        if (DatePicker.currentYear == this.myYear){
          addDiv = html`<div class="option-item option-selected option-current" @click="${this.yearSelect.bind(this)}">${year}</div>`
        } else {
          addDiv = html`<div class="option-item option-current" @click="${this.yearSelect.bind(this)}">${year}</div>`
        }
      } else {
        if(year == this.myYear){
          addDiv = html`<div class="option-item option-selected" @click="${this.yearSelect.bind(this)}">${year}</div>`
        } else {
          addDiv = html`<div class="option-item" @click="${this.yearSelect.bind(this)}">${year}</div>`
        }
      }
      const dialogEL = document.createElement('ps-year')
      render(addDiv, dialogEL)
      this.optionDiv.append(dialogEL) 
    })
    this.optionDiv.scrollTo(0, 0)
  }
  
  showLanguages(){ 
    Element.removeAllChildren(this.optionDiv)
    this.optionDiv.classList.remove('hidden')
    //insert selected language at top (if month - show selected & current)
    const thisLang = DatePicker.getMyLanguage(DatePicker.langCode) 
    let addCurrentDiv = ''
    const currentEl = document.createElement('ps-lang')
    //if selected (other lang) also show at top
    if (this.myLang == DatePicker.langCode) { 
      addCurrentDiv = html`<div id="${DatePicker.langCode}" class="option-item option-selected option-current" @click="${this.closeOptions.bind(this)}">${thisLang}</div>`
    } else { 
      addCurrentDiv = html`<div id="${DatePicker.langCode}" class="option-item option-current" @click="${this.langSelect.bind(this)}">${thisLang}</div>`
      const selectedLang = DatePicker.getMyLanguage(this.myLang)
      const addSelLangEl = document.createElement('ps-lang')
      const addSelLangDiv = html`<div id="${DatePicker.myLang}" class="option-item option-selected" @click="${this.closeOptions.bind(this)}">${selectedLang}</div>`
      render(addSelLangDiv, addSelLangEl)
      this.optionDiv.append(addSelLangEl) 
     }
    render(addCurrentDiv, currentEl)
    this.optionDiv.append(currentEl) 
    // Add available languages
    languages.forEach(language => { 
      const newLang = DatePicker.getMyLanguage(language.code)
      const dialogEL = document.createElement('ps-lang')
      const addDiv = html`<div id="${language.code}" class="option-item" @click="${this.langSelect.bind(this)}">${newLang}</div>`
      render(addDiv, dialogEL)
      this.optionDiv.append(dialogEL) 
    })
    this.optionDiv.scrollTo(0, 0)
  }

  langSelect(e){
    const newLang =  e.target.getAttribute("id")
    this.myLang = newLang
    this.daysForLocale(this.myLang, 'short') 
    this.insertMonth(this.myMonth, this.myLang) 
    this.closeOptions()
  }

  closeWidget(){
    const widgetDiv = document.getElementById("widget")
    Element.removeAllChildren(widgetDiv)
  }

  closeOptions(){
    this.optionDiv.classList.add('hidden')
  }

  durationToggle(){
    const endDateDiv = this.shadowRoot.getElementById("result2")
    const endDateWrapper = this.shadowRoot.getElementById("result2-wrapper")
    const startDateDiv = this.shadowRoot.getElementById("result1")
    if(this.durationOpt == false){
      this.durationOpt = true
      this.updateDisplay('From', 'result1', true)//true for <p>
      this.updateDisplay('Reset', 'duration', true)
      endDateWrapper.style.maxHeight = Math.max(endDateWrapper.scrollHeight) + "px"; 
      if(this.startDate !== null){
        endDateDiv.classList.add('result-active')
        startDateDiv.classList.remove('result-active')
      }
    }
    else {
      if(endDateDiv.classList.contains('result-active')){
        endDateDiv.classList.remove('result-active')
        startDateDiv.classList.add('result-active')
      }
      this.durationOpt = false
      this.endDate = null
      endDateWrapper.style.maxHeight = null; 
      //reset all 
      this.startDate = null
      this.endDate = null
      this.populateCalendar(this.myYear, this.myMonth)
      this.updateDisplay('Select date...', 'result1')
      this.updateDisplay('Select date...', 'result2')
      this.updateDisplay('On', 'result1', true)
      this.updateDisplay('Duration', 'duration', true)
    }
  }

  focusDate(e){
    const actDiv = e.target.closest('div')
    if(actDiv.classList.contains('result-active')){return}
    const deActive = this.shadowRoot.querySelector(".result-active")
    deActive.classList.remove('result-active')
    actDiv.classList.add('result-active')
  }


  render(){    
    return html`
      <style>      
        * { box-sizing: border-box; margin: 0px; padding: 0px; user-select: none; }
        .widget-container { 
          position: absolute;
          font-weight: bold; font-family: calibri;
          top: 0; left: 0; right: 0; bottom: 0;
          z-index: 100;
          overflow: auto;
          background-color: rgb(0,0,0,0.7);
          transition: background-color .5s ease-out;
          display: flex; align-items: center; justify-content: center;
        }
        .widget-screen {
          background: var(--date-picker3);
          border-radius: 1em;
          color: white;
          position: relative;
          width: 400px;
          padding: 5px; 
        }

  /* change colours via vars */

        .hidden { display: none;  }
        #options {
          height: 350px;
          overflow: auto;
          background: white;
          margin: .8em;
          padding: 1em 2em;
          position: absolute;
          left: 0; right: 0;
          box-shadow: 2px 2px 10px #646464;
          font-size: 1.3em;
          z-index: 20;
        }
        .option-item {
          display: block;
          width: 90%;
          margin: 5px auto;
          color: black;
          background-color: var(--date-pickerC);
          border-radius: 5em;
          text-align: center;
          padding: .3em;
          cursor: pointer;
        }
        .option-selected { background-color: var(--date-picker1); color: white; }
        .option-current { border: solid 2px var(--date-picker3); }
        
        #duration { margin: 15px auto; color: var(--date-picker1); background-color: var(--date-picker2); }

        .header-bar { 
          text-align: right; padding: 5px 5px 10px 5px; width: 100%;
          display: flex; align-items: center; justify-content: space-between;
        }
        .header-bar h2 { font-size: 2em; cursor: pointer; }

        .year-select {
          cursor: pointer; font-size: 1.6em;
          display: flex; align-items: center; justify-content: center;
        }

        .year-select img { width: 30px; }
        .close-icon, #world-img, #next-month, #prev-month { padding: 5px; cursor: pointer;  }
        .close-icon, #next-month, #prev-month { width: 40px; }
        #world-img { width: 50px; }
        #year-arrow { width: 20px; margin-left: 7px; }

        #year-arrow { width: 20px; margin-left: 7px; }
        #next-month { transform: rotate(180deg); }

        .calendar-cont { width: 100%; padding: 0 10px 10px 10px; min-height: 100px;
          background: white; font-size: 1.3em; }

        .calendar-grid {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          grid-column: span 7; text-align: center;          
        }

        #day-numbers { min-height: 340px; }  /* Prevent from jumping when months have less days */
        #weekdays  { font-size: 0.7em; padding-top: 7px;  }
        .day-name { color: var(--date-pickerA); }

/*  Results section*/
        .result-bar {
          background: white;
          margin: 15px 15px 0 15px;
          color: var(--date-pickerB);
          padding: .5em 1em;
          border-radius: 5px;
          position: relative;
          cursor: pointer;
        }
        .result-active { box-shadow: 0px 0px 10px #646464; }
        .result-bar span {
          position: absolute;
          width: 100%;
          top: 7px;
          color: var(--date-pickerA);
          font-size: 1.1em;
          text-align: center;
        }
        #result2-wrapper {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.4s ease-out;
        }
        

/* Day Numbers (dates) styling */    
        #day-numbers p { aspect-ratio: 1 / 1; border-radius: 3em; 
            display: flex; align-items: center; justify-content: center; }
        .these-days, .other-days { aspect-ratio: 1 / 1; margin-top: 4px;  } 
        .these-days { color: black; cursor: pointer; }
        .other-days { color: var(--date-pickerB); }
/* id & click on div NOT p tags  var(--date-pickerB)  9B9B9B */
        .today p {  text-decoration-line: underline;  }
        .today p::before, .today p::after {
          content: "i";
          color: rgba(203, 203, 203, 0);
        }

        .date-selected p { background: var(--date-picker1); color: white; border: 3px solid var(--date-picker2); }
        .mid-range { background: var(--date-picker2);  }
        .start-range { background-image: linear-gradient(to right, white 50% ,var(--date-picker2) 50%); }
        .end-range { background-image: linear-gradient(to right, var(--date-picker2) 50% ,white 50%); }

      </style>

      <div class="widget-container" @click="${this.stayFocused}">
        <div class="widget-screen">
          <div id="options" class="hidden"></div>
          
          <div class="header-bar" >
            <svg version="1.1" @click="${this.showLanguages}"  baseProfile="tiny" id="world-img" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50" xml:space="preserve">
              <circle fill="none" stroke="#FFFFFF" stroke-width="2" stroke-miterlimit="10" cx="25.059" cy="25.09" r="19.87"/>
              <path fill="none" stroke="#FFFFFF" stroke-width="2" stroke-miterlimit="10" d="M40.713,12.67
                c-3.637,3.182-9.298,5.227-15.654,5.227c-6.387,0-12.07-2.064-15.705-5.271"/>
              <path fill="none" stroke="#FFFFFF" stroke-width="2" stroke-miterlimit="10" d="M40.713,37.511
                c-3.637-3.182-9.298-5.227-15.654-5.227c-6.387,0-12.07,2.064-15.705,5.271"/>
              <g>
                <path fill="none" stroke="#FFFFFF" stroke-width="2" stroke-miterlimit="10" d="M24.841,5.707
                  c6.028,4.215,9.989,11.322,9.989,19.384c0,8.061-3.961,15.17-9.989,19.384"/>
                <path fill="none" stroke="#FFFFFF" stroke-width="2" stroke-miterlimit="10" d="M24.841,44.475
                  c-6.027-4.214-9.989-11.323-9.989-19.384c0-8.062,3.962-15.169,9.989-19.384"/>
                <path fill="none" stroke="#FFFFFF" stroke-width="2" stroke-miterlimit="10" d="M34.83,25.09c0-8.062-3.961-15.169-9.989-19.384
                  c-6.027,4.215-9.989,11.322-9.989,19.384c0,8.061,3.962,15.17,9.989,19.384C30.869,40.261,34.83,33.151,34.83,25.09z"/>
              </g>
              <line fill="none" stroke="#FFFFFF" stroke-width="2" stroke-miterlimit="10" x1="25.059" y1="5.209" x2="25.059" y2="44.972"/>
              <line fill="none" stroke="#FFFFFF" stroke-width="2" stroke-miterlimit="10" x1="5.205" y1="25.09" x2="44.912" y2="25.09"/>
            </svg>

            <div class="year-select" @click="${this.showYearRange}">
              <p id="selected-year"></p>
              <svg version="1.1" baseProfile="tiny" id="year-arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px" y="0px" width="40px" height="40px" viewBox="0 0 60 60" xml:space="preserve">
              <polygon fill="#FFFFFF" points="10,20 50,20 30,50 "/>
              </svg>
            </div>

            <svg version="1.1" class="close-icon" alt="Close" @click="${this.closeWidget}"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" xml:space="preserve">
              <line fill="none" stroke="#FFFFFF" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="6.65" y1="7.095" x2="43.639" y2="43"/>
              <line fill="none" stroke="#FFFFFF" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="43.639" y1="7.095" x2="6.65" y2="43"/>
            </svg>
          </div>

          <div class="header-bar">
              <svg version="1.1" @click="${this.prevMonth.bind(this)}" id="prev-month" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" xml:space="preserve">
                <polyline fill="none" stroke="#FFFFFF" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
                  34,6 15,25 34,43 "/>
              </svg>
              <h2 id="selected-month" @click="${this.showMonthRange.bind(this)}"></h2>
              <svg version="1.1" @click="${this.nextMonth.bind(this)}" id="next-month" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" xml:space="preserve">
                <polyline fill="none" stroke="#FFFFFF" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
                  34,6 15,25 34,43 "/>
              </svg>
          </div>


          <div class="calendar-cont">   
            <div id="weekdays" class="calendar-grid">
              <div class="day-name"><p></p> </div>
            </div>   

    <!-- day numbers Dates  -->
            <div id="day-numbers" class="calendar-grid">
              <!-- classes: these-days other-days today mid-range start-range end-range -->
              <!-- <div class="these-days"></div> -->
            </div>

          </div><!-- end calendar  -->

          <div id="result1" class="result-bar result-active" @click="${this.focusDate}"> <p>On:</p> <span>Select Date...</span> </div>
          <div id="result2-wrapper"><div id="result2" class="result-bar" @click="${this.focusDate}"> <p>Until:</p> <span>Select Date...</span></div></div>
          <div id="duration" class="option-item" @click="${this.durationToggle}"><p>Duration</p></div>

        </div> <!-- close widget-screen-->
      </div> <!-- close widget-container -->
    `
  }
})
