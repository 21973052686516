import {LitElement, html} from 'lit'
import {anchorRoute} from '../../Router'
import Element from '../../Element'


customElements.define('widget-icon', class Features extends LitElement {
  constructor(){
    super()    
  } 

  static get properties(){
    return {
      widget: { type: String }
      // widget: { type: String },
      // successInfo: { type: String }
    }
  }
  
  // <widget-icon widget="app-calculator"></widget-icon>

  firstUpdated(){
    super.firstUpdated()
  }

  openWidget(){
    // const component = `<${this.widget}></${this.widget}>`
    const newComponent = Element.createNewElement(`${this.widget}`)
    const widgetDiv = document.getElementById("widget")
    widgetDiv.appendChild(newComponent)
    newComponent.addEventListener('close-el',() => newComponent.remove()) 
  }

  // https://developer.mozilla.org/en-US/docs/Web/Events/Creating_and_triggering_events
  // 1 add the addEventListener on the object to be removed
      // newComponent.addEventListener('close-el',() => newComponent.remove())
  // 2 in an internal element, dispatch the event:  (SEE PS-PORTFOLIO-FRONTEND APP-IMAGE-TEXT WIDGET COMPONENT)
      // this.dispatchEvent( new CustomEvent("close-el"));



  // addEl(){
//   const newComponent = Element.createElement('div', 'unique-div-id', 'append-div')
//   const fullEl = Element.addText(newEmptyEl, 'This div was created with added text')
//   const parent = document.getElementById('parent')
//   parent.appendChild(fullEl)
// }


  //  ${this.successSource == "thread" ? html`` : html``}

  //  add function to write the widget into the "widget" div @index.html - insert html
  // <app-calculator></app-calculator>


  render(){    
    return html`
      <style>      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }
        .widget-icon { text-align: center; cursor: pointer; }
        ::slotted(h4) {
          margin: 5px 0;
        }
        ::slotted(p) {
          /* padding: 6px; */
          font-family: calibri;
          color: darkgrey;
          margin: 0px;
        }
        ::slotted(img) {
          /* width: 120px; */
        }

        
        @media all and (max-width: 750px){ 

        }
      </style>

      <div class="widget-icon" @click="${this.openWidget}">
        <slot></slot>
      </div>

    `
  }
})
