import {LitElement, html} from 'lit'
import Element from '../../Element'

customElements.define('app-dark-theme', class Features extends LitElement {
  constructor(){
    super()    
  } 

  firstUpdated(){
    super.firstUpdated()
    this.theme = "light"
    this.toggleTheme() //but starts with animation of shape!
  }

  closeWidget(){
    const widgetDiv = document.getElementById("widget")
    Element.removeAllChildren(widgetDiv)
  }

  toggleTheme(){
    const themeScreen = this.shadowRoot.getElementById("theme-screen")
    const sky = this.shadowRoot.getElementById("btn-clouds")
    const sunmoon = this.shadowRoot.getElementById("sun-moon")
    const clouds = this.shadowRoot.querySelectorAll(".cloud-path")
    const svgImage2 = this.shadowRoot.getElementById("sm-svg")           
    const sunPath = "M147.384,75.23c0,9.8-21.172,10.587-24.717,19.103c-3.577,8.59,10.443,24.893,4.014,31.42c-6.559,6.658-23.619-7.466-32.348-3.753c-8.649,3.68-8.952,25.227-18.945,25.227c-9.725,0-10.258-21.064-18.721-24.56c-8.705-3.595-25.293,10.289-31.887,3.771c-6.797-6.718,8.258-21.798,4.553-30.771C25.84,87.206,3.392,84.953,3.392,75.23c0-10.01,21.583-10.568,25.275-19.23c3.691-8.662-10.509-25.427-3.913-31.952c6.747-6.675,22.629,8.222,31.579,4.618c8.305-3.344,9.554-25.432,19.055-25.432c9.739,0,10.473,21.594,18.945,25.099c8.901,3.682,25.458-10.563,32.14-3.835c6.434,6.478-7.75,22.632-4.14,31.168C125.976,64.28,147.384,65.29,147.384,75.23z"
    const moonPath = "M86.739,99.439c6.667,4.89,15.333,9.778,23.333,12c7.97,2.214,22.442,2.628,16.727,8.43c-5.83,5.919-12.804,10.707-20.563,14.007c-7.688,3.271-16.148,5.081-25.031,5.081c-8.644,0-16.888-1.714-24.41-4.82c-7.737-3.195-14.712-7.864-20.573-13.658c-6.042-5.971-10.901-13.137-14.194-21.113c-3.105-7.521-4.818-15.762-4.818-24.404c0-8.897,1.816-17.372,5.099-25.071c3.281-7.699,8.027-14.624,13.891-20.424c5.997-5.934,13.161-10.689,21.117-13.893c7.382-2.972,15.445-4.608,23.89-4.608c8.657,0-1.528,10.641-5.349,17.84c-4.228,7.969-6.98,17.142-7.704,25.54c-0.746,8.65-0.178,18.619,3.031,26.206C74.422,88.207,79.614,94.215,86.739,99.439z"
    themeScreen.classList.toggle("dark")
    if(this.theme == "light"){ 
      this.theme = "dark"
      sky.style.backgroundColor = "#15226D" 
      clouds.forEach(cloud => {
        cloud.setAttribute("fill", "url(#grad2)")
      })
      const leftPos = sky.offsetWidth * 2/3
      sunmoon.style.left = leftPos + "px";
      sunmoon.style.transform = "rotate(210deg)"
      svgImage2.setAttribute('fill', "#FFF") 
      svgImage2.setAttribute('d', moonPath)
    } else { 
      this.theme = "light" 
      sky.style.backgroundColor = "#AFEEF9"     
      clouds.forEach(cloud => {
        cloud.setAttribute("fill", "url(#grad1)")
      })
      sunmoon.style.left = "0";
      sunmoon.style.transform = "rotate(0deg)"
      svgImage2.setAttribute('fill', "#FFCF03") 
      svgImage2.setAttribute('d', sunPath) 
    }
  } //end of toggle


  render(){    
    return html`
      <style>      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }
        .widget-container { 
          position: absolute;
          font-weight: bold; font-family: calibri;
          top: 0; left: 0; right: 0; bottom: 0;
          z-index: 30;
          overflow: auto;
          background-color: rgb(0,0,0,0.6);
          transition: background-color .5s ease-out;
          display: flex; align-items: center; justify-content: center;
        }
        .widget-screen {
          background: black;
          border-radius: 1em;
          color: white;
          position: relative;
          width: 400px;
          padding: 15px; 
        }

        .close { width: 100% ; height: 100px; display: flex; align-items: center; justify-content: center;
          background-color: white; border-radius: 0.5em; }
        .close-icon { position: absolute; top: 25px; right: 25px; width: 40px; padding: 5px; cursor: pointer;  }

        
        /* TOGGLE BUTTON: 
        Changing holder, changes size */
        #btn-holder { width: 150px; height: 50px; }  /* width:height = 3:1 */

        #the-button { background-color: #AFEEF9; cursor: pointer;
          position: relative; width: 100%; height: 100%; border-radius: 10em; overflow: hidden; 
          transition: background-color 1.5s ease;}

        /* svg id="clouds" - update colour via  div=sun-moon svg=sunmoon */
        #btn-clouds, #sm-svg  { transition: all 1.5s; }
        /* edit position left = 0 via javascript  sunmoon  */
        #sun-moon { position: absolute; top: 0; height: 100%; left: 0px; padding: 3px; transition: all 1.5s ease; }
        

        /* Inner screen div for example  display: flex; */
        .inner-screen { width: 100%; height: 400px; margin-top: 20px; border-radius: 0.5em;
          background-color: rgb(244, 250, 226); color: black; font-size: 2em;
          display: flex; align-items: center; justify-content: center; }
        .dark { background-color: rgb(2, 2, 71); color: white; }
        .inner-content {
          text-align: center;
        }


      </style>

      <div class="widget-container" @click="${this.stayFocused}">
        <div class="widget-screen">
          <div class="close"> 
            <img class="close-icon" alt="Close"  src="/images/icons/23close.svg" @click="${this.closeWidget}">
            <div id="btn-holder">

              <div id="the-button" @click="${this.toggleTheme}">
                <div id="btn-clouds" >
                  <svg id="clouds" version="1.1" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px" y="0px" width="100%" viewBox="-208.5 0 358.5 150" xml:space="preserve">
                    <defs>
                      <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="50%" style="stop-color:rgb(255, 255, 255);stop-opacity:1" />
                        <stop offset="100%" style="stop-color:rgb(203, 203, 203);stop-opacity:0.6" />
                      </linearGradient>
                      <linearGradient id="grad2" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="30%" style="stop-color:rgb(255, 255, 255);stop-opacity:0.5" />
                        <stop offset="100%" style="stop-color:rgb(203, 203, 203);stop-opacity:0.2" />
                      </linearGradient>
                    </defs>
                    <path class="cloud-path" fill="url(#grad1)" d="M-75.657,93.923c6.995-22.171-22.618-41.07-44.583-24.79c-5.565-10.626-17.47-16.131-30.417-16.131
                      c-21.505,0-43.451,12.647-35.267,41.814c-9.375,0-16.03,8.636-16.03,17.416c0,12.153,9.929,17.257,16.03,18.893
                      c9.496,2.548,22.322,0.744,26.042-5.356c3.422,6.696,13.408,13.02,27.975,13.689c12.946,0.597,22.322-5.506,26.488-13.393
                      c5.208,9.523,22.471,10.862,31.807,8.334c5.066-1.373,18.911-10.045,19.788-22.167C-53.038,101.364-61.074,91.841-75.657,93.923z"/>
                    <path class="cloud-path" fill="url(#grad1)" d="M19.716,55.255c-6.995-22.171,22.618-41.069,44.583-24.789c5.565-10.626,17.469-16.132,30.417-16.132
                      c21.506,0,43.451,12.647,35.268,41.814c9.374,0,16.029,8.636,16.029,17.416c0,12.155-9.929,17.257-16.029,18.894
                      c-9.496,2.547-22.322,0.743-26.042-5.357c-3.423,6.696-13.408,13.02-27.975,13.689c-12.946,0.597-22.323-5.506-26.489-13.393
                      c-5.208,9.523-22.471,10.862-31.807,8.334C12.604,94.358-1.241,85.687-2.118,73.564C-2.903,62.696,5.132,53.173,19.716,55.255z"/>
                  </svg>
                </div> <!-- <div id="clouds"> btn-clouds  -->
                <div id="sun-moon"> 
                  <svg version="1.1" baseProfile="tiny" id="sunmoon2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px" y="0px" height="100%" viewBox="0 0 150 150" xml:space="preserve">
                    <!-- <defs> -->
                    <!-- class="sun-class"  -->
                    <path  id="sm-svg" fill="#FFCF03" d="M147.384,75.23c0,9.8-21.172,10.587-24.717,19.103c-3.577,8.59,10.443,24.893,4.014,31.42
                      c-6.559,6.658-23.619-7.466-32.348-3.753c-8.649,3.68-8.952,25.227-18.945,25.227c-9.725,0-10.258-21.064-18.721-24.56
                      c-8.705-3.595-25.293,10.289-31.887,3.771c-6.797-6.718,8.258-21.798,4.553-30.771C25.84,87.206,3.392,84.953,3.392,75.23
                      c0-10.01,21.583-10.568,25.275-19.23c3.691-8.662-10.509-25.427-3.913-31.952c6.747-6.675,22.629,8.222,31.579,4.618
                      c8.305-3.344,9.554-25.432,19.055-25.432c9.739,0,10.473,21.594,18.945,25.099c8.901,3.682,25.458-10.563,32.14-3.835
                      c6.434,6.478-7.75,22.632-4.14,31.168C125.976,64.28,147.384,65.29,147.384,75.23z"/>
                  </svg>
                </div>   <!-- <div id="sun-moon">  -->
              </div><!-- <div id="the-button">  -->
            </div>  <!-- button holder  -->
          </div> <!-- close div = header here  -->

          <div id="theme-screen" class="inner-screen">
            <div class="inner-content">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="114px" height="114.5px" viewBox="0 0 114 114.5" enable-background="new 0 0 114 114.5" xml:space="preserve">
              <g>
                <path fill="#07A898" d="M45.396,76.334c0.592,0.615,1.655,1.282,2.949,1.901c1.283,0.613,2.728,1.155,4.229,1.608
                  c3.013,0.909,6.248,1.488,9.498,1.715c6.504,0.454,13.157-0.48,18.948-3.32c5.809-2.794,10.615-7.691,13.676-13.911
                  c3.1-6.207,4.571-13.447,5.025-20.764c0.057-0.916,0.096-1.835,0.129-2.756c-0.016-0.882-0.072-1.869-0.156-2.739
                  c-0.163-1.792-0.417-3.61-0.813-5.38c-0.78-3.55-2.025-7.002-3.753-10.204c-3.44-6.424-8.834-11.745-15.185-15.383
                  c-6.353-3.646-13.631-5.724-20.99-5.938c-3.676-0.107-7.382,0.295-10.938,1.286c-3.542,1.009-6.945,2.66-9.759,5.098
                  c2.75-2.509,6.132-4.262,9.679-5.37c3.561-1.092,7.298-1.596,11.026-1.583c7.46,0.029,14.919,1.941,21.531,5.514
                  c6.605,3.563,12.35,8.918,16.117,15.544c1.89,3.298,3.288,6.884,4.198,10.583c0.463,1.854,0.775,3.724,0.998,5.635
                  c0.13,0.98,0.161,1.855,0.26,2.833c0.11,0.939,0.21,1.88,0.291,2.827c0.644,7.539,0.333,15.396-2.125,23.049
                  c-1.227,3.813-3.033,7.536-5.471,10.909c-2.424,3.38-5.489,6.37-8.953,8.776c-6.965,4.846-15.269,7.314-23.538,8.004
                  c-4.16,0.341-8.348,0.258-12.594-0.335c-2.126-0.297-4.268-0.728-6.472-1.375c-2.18-0.654-4.539-1.512-6.973-3.061L45.396,76.334z"
                  />
                <path fill="#00D1C3" d="M78.383,62.485L69.79,46.172l12.012-8.474c0,0-5.009-3.916-6.668-5.012
                  c-3.315-2.198-6.834-4.113-10.474-5.741c-3.606-1.609-7.386-2.874-11.238-3.794c-3.831-0.91-7.849-1.527-11.624-1.627l0.127-6.426
                  c4.521,0.167,8.742,0.886,12.976,1.921c4.208,1.049,8.324,2.465,12.288,4.279c3.924,1.8,7.705,3.904,11.298,6.333
                  c1.793,1.213,3.538,2.508,5.224,3.896c1.707,1.424,7.164,7.089,7.164,7.089l-14.19,9.698L78.383,62.485z"/>
                <path fill="#07A898" d="M41.831,21.611c1.566,7.556,2.74,12.238,3.519,20c0.272,3.878,0.353,7.74,0.441,11.606
                  c0.054,2.848,0.084,8.535,0.084,8.535l15.716-8.832l6.642,15.329l10-6.034l2.949,5.701L68.243,73.66l-2.317,1.028l-0.86-2.189
                  L59.584,58.54L42.32,67.682c0,0,0.636-18.317,0.56-25.967C42.936,34.082,42.586,29.283,41.831,21.611z"/>
                <path fill="#00D1C3" d="M25.817,4.104c3.598,8.391,5.495,17.525,6.646,26.671c1.125,9.171,1.446,18.446,1.272,27.707
                  c-0.407,18.538-2.689,37.039-6.694,55.371l-15.315-4.592c6.251-16.527,11.055-33.854,14.052-51.52
                  c1.473-8.834,2.477-17.772,2.695-26.763C28.66,22.006,28.146,12.926,25.817,4.104z"/>
              </g>
              </svg>
              <br>
              Digital Design
              </div>

          </div> <!-- close div = example screen  -->
        </div>
      </div>
    `
  }
})
