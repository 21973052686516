import {LitElement, html} from 'lit'
import Element from '../../Element'

customElements.define('app-calculator', class Features extends LitElement {
  constructor(){
    super()    
  } 

  firstUpdated(){
    super.firstUpdated()
    this.output = this.shadowRoot.getElementById("answer")
    this.sum = this.shadowRoot.getElementById("sum")
    this.sumArray = []
    this.stayFocused()
    this.mdOperations = [] 
    this.sumEquals = false
    this.testNegInt()
  }
  testNegInt(){
    console.log('Add functionailty for negative numbers @ - click (only from beginning)')
    console.log('-1 + 5 = ' + (-1 + 5))
    console.log('-1 + 5 = ' + (Number('-1') + 5))

  }
  //keyboard disabled via input
  resetSum(){
    this.sumEquals = false
    this.sumArray = []
    Element.removeAllChildren(this.sum) 
  }

  equalOperation(){
    this.sumEquals = true //set the equal to true - new sum if user wants to continue
    //find all multiplication & division first    
    this.checkMultDiv()
    while (this.mdOperations.length > 0) {
      this.calculateSum(this.mdOperations[0].arIndex) //pass through the index number
      this.mdOperations = []
      this.checkMultDiv()
    }
    //repeat now for subtraction & addition
    while (this.sumArray.length > 1) {
      this.calculateSum(1) //pass through the index number of the operator(1=2nd value)
    }
    this.output.value = this.sumArray[0].value 
    this.output.focus()
  }


  //check to see if multiplication or divisions are present in this.sumArray
  checkMultDiv(){
    this.mdPositions = [] //reset 
    this.sumArray.forEach((sum, index) => {
      let mdOp 
      switch (sum.type) {
        case 'divide':
          mdOp = { op: "divide", arIndex: index }
          this.mdOperations.push(mdOp) 
          break;
        case 'multiply':
          mdOp = { op: "multiply", arIndex: index }
          this.mdOperations.push(mdOp)
          break;
        default: return
      }
    })
  }


  calculateSum(i){
    // get the numbers and operator
    const operation =  this.sumArray[i].type
    const n1 = Number(this.sumArray[i-1].value)
    const n2 = Number(this.sumArray[i+1].value)
    let newNumber = {}
    let answer 
    switch(operation) {
      case 'multiply':  answer = n1 * n2; break;
      case 'divide': answer = n1 / n2; break;
      case 'add':  answer = n1 + n2; break;
      case 'subtract': answer = n1 - n2; break;
      default: return
    }
    newNumber  = { 
          type : "number", 
          value : answer 
    }
    const pos = i-1
    this.sumArray.splice(pos, 3, newNumber);
    return 
  }
  
  operatorClick(e){
    this.clickColour(e)
    //if no number ...
    if(this.output.value == ""){
      if(this.sumArray.length == 0){return}//if array is empty = return
      const lastItemId = this.sumArray[this.sumArray.length - 1].type  //findLast(lastItem).id //console.log(items[items.length - 1]);
      if(e.target.id == lastItemId){return}//if last function same = return
      if( e.target.id == "equal"){
        if(this.sumArray.length <= 2){ return }//if only one number (with one function) = return
        else { this.equalOperation() } 
      }
      //swap out in div:
      let arrayOperator = {
        type : e.target.id,
        value : " " + e.target.innerText + " "
      }
      //remove the last array item
      this.sumArray.splice(-1, 1)
      //clear the div
      Element.removeAllChildren(this.sum) 
      //rewrite each sum part
      Element.appendTextNodes(this.sum, this.sumArray)
      //then add this operator 
      Element.appendTextNodes(this.sum, arrayOperator.value)
      //if not equals - add it to the array
      if( e.target.id !== "equal"){ this.sumArray.push(arrayOperator) }
      this.output.focus()
      return
    } else {
      // there was a number/value...
      if( this.output.value == "-"){ return }
//RESET - if the calculation was already done
      if(this.sumEquals === true){ 
        this.resetSum()
        this.mdOperations = []
      }
      //get number and add to array
      let arrayNumber = { 
            type : "number", //(or operator name - from ID)
            value : this.output.value //(or operator character with space)
      }
      let arrayOperator = {
        type : e.target.id,
        value : " " + e.target.innerText + " "
      }
      this.sumArray.push(arrayNumber)
      Element.appendTextNodes(this.sum, arrayNumber.value)
      Element.appendTextNodes(this.sum, arrayOperator.value)

      if( e.target.id == "equal" && this.sumArray.length <= 2){ return }

      //if equals (run calculateSum function and return)
      if( arrayOperator.type == "equal"){
        if(this.sumArray.length > 2 ){this.equalOperation()}
        return
      }
      //don't want the equal sent to the array - other operations with equalOperation()
      //will be even until equal is hit (after greater than 2 in array)
      this.sumArray.push(arrayOperator)
      this.output.value = ''
      this.output.focus()
    }
  }

  valueClick(e){
    this.clickColour(e)
    if(this.sumEquals === true){
      this.resetSum()
    } 
    const myValue = this.output.value
    let newValue = "-" 
    if(Array.from(myValue)[0] == "-"){ newValue = myValue.substring(1) }
    else { newValue = "-" + myValue }
    this.output.value = newValue
    this.output.focus()
  }

  numberClick(e){
    this.clickColour(e)
    const newNum = e.target.id
    if(this.sumEquals === true){
      this.resetSum()
      this.output.value = newNum
    } else{
      this.output.value += newNum
    }
    this.output.focus()
  }

  backspaceClick(e){
    this.clickColour(e)
    if(this.sumEquals === true){ 
      this.resetSum() 
    } 
    const str = this.output.value
    const newValue = str.slice(0, -1);
    this.output.value = newValue
    this.output.focus()
  }

  clearSum(e){
    this.clickColour(e)
    Element.removeAllChildren(this.sum) 
    this.sumArray = []
    this.output.value = ''
    this.output.focus()
  }

  clearEntry(e){
    this.clickColour(e)
    if(this.sumEquals === true){ 
      this.resetSum() 
    } 
    this.output.value = ''
    this.output.focus()
  }

  closeWidget(){
    const widgetDiv = document.getElementById("widget")
    Element.removeAllChildren(widgetDiv)
  }

  stayFocused(){ this.output.focus() }

  clickColour(e){ 
    const myBtn = e.target
    if(myBtn.classList.contains('action')){
      myBtn.classList.remove('action')
      myBtn.classList.add('action-click')
    } 
    if(myBtn.classList.contains('info')){
      myBtn.classList.remove('info')
      myBtn.classList.add('info-click')
    }
    if(myBtn.classList.contains('number')){
      myBtn.classList.remove('number')
      myBtn.classList.add('number-click')
    }
    setTimeout(() => {  
      if(myBtn.classList.contains('action-click')){
        myBtn.classList.remove('action-click')
        myBtn.classList.add('action')
      } 
      if(myBtn.classList.contains('info-click')){
        myBtn.classList.remove('info-click')
        myBtn.classList.add('info')
      }
      if(myBtn.classList.contains('number-click')){
        myBtn.classList.remove('number-click')
        myBtn.classList.add('number')
      }
    }, 200);
  }

  render(){    
    return html`
      <style>      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }
        .widget-container { 
          position: absolute;
          font-weight: bold; font-family: calibri;
          top: 0; left: 0; right: 0; bottom: 0;
          z-index: 30;
          overflow: auto;
          background-color: rgb(0,0,0,0.6);
          transition: background-color .5s ease-out;
          display: flex; align-items: center; justify-content: center;
        }
        .widget-screen {
          background: black;
          border-radius: 1em;
          color: white;
          position: relative;
          width: 400px;
          padding: 5px; 
        }
        .close-icon { width: 40px; padding: 5px; cursor: pointer;  }
        .close { text-align: right; display: block; margin: 10px 10px 0 0 ; height: 50px; }
        .sum { text-align: right; display: block; color: grey; font-size: 1.5em; margin: 0 20px; height: 30px; }
        .answer { 
          text-align: right; 
          display: block; 
          color: rgb(7, 212, 231); 
          font-size: 2em;
          padding: 10px 20px; 
          height: 50px; width: 100%;
          margin: 10px auto;
          background-color: black;
        }
        input { background: none; border:0; outline:0; }
        input:focus,input:active{background: none;outline:none!important;}
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none; /* display: none; <- Crashes Chrome on hover */
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }
        input[type=number] { -moz-appearance:textfield; /* Firefox */ }
        .grid { 
          text-align: center;
          display: grid;
          margin: auto;
          grid-template-columns: 25% 25% 25% 25%;
          grid-auto-flow: row dense;
        }
        .btn {
          font-size: 2em;
          padding: .5em .5em;
          border-radius: 0.3em;
          margin: 2px;
          cursor: pointer;
        }
        .info { background-color: grey; }
        .number { background-color: darkgrey; }
        .action { background-color: rgb(7, 167, 182); }
        .info-click { background-color: rgb(106, 106, 106); }
        .number-click { background-color: rgb(147, 147, 147); }
        .action-click { background-color: rgb(6, 145, 157); }
      </style>

      <div class="widget-container" @click="${this.stayFocused}">
        <div class="widget-screen">
          <div class="close"><img class="close-icon" alt="Close"  src="/images/icons/23close.svg" @click="${this.closeWidget}"></div>
          <div id="sum" class="sum"></div>
          <input class="answer" type="text"  id="answer" step="any" onkeypress="return false;" readonly>
          <div class="grid">
            <div id="ce" class="btn info" @click="${this.clearEntry}">CE</div>
            <div id="c" class="btn info" @click="${this.clearSum}">C</div>
            <div id="b" class="btn info" @click="${this.backspaceClick}">B</div>
            <div id="divide" class="btn action" @click="${this.operatorClick}">/</div>

            <div id="7" class="btn number" value="7" @click="${this.numberClick}">7</div>
            <div id="8" class="btn number" value="8" @click="${this.numberClick}">8</div>
            <div id="9" class="btn number" value="9" @click="${this.numberClick}">9</div>
            <div id="multiply" class="btn action" @click="${this.operatorClick}">*</div>

            <div id="4" class="btn number" value="4" @click="${this.numberClick}">4</div>
            <div id="5" class="btn number" value="5" @click="${this.numberClick}">5</div>
            <div id="6" class="btn number" value="6" @click="${this.numberClick}">6</div>
            <div id="subtract" class="btn action"  @click="${this.operatorClick}">-</div>

            <div id="1" class="btn number" value="1" @click="${this.numberClick}">1</div>
            <div id="2" class="btn number" value="2" @click="${this.numberClick}">2</div>
            <div id="3" class="btn number" value="3" @click="${this.numberClick}">3</div>
            <div id="add" class="btn action" @click="${this.operatorClick}">+</div>

            <div id="h" class="btn info" @click="${this.valueClick}">-/+</div>
            <div id="0" class="btn number" @click="${this.numberClick}">0</div>
            <div id="." class="btn info" @click="${this.numberClick}">.</div>
            <div id="equal" class="btn action" @click="${this.operatorClick}">=</div>
          </div>
        </div>
      </div>
    `
  }
})
//this.output.value
//this.output.value = ''