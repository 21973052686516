import App from '../../App'
import {render, html} from 'lit/html.js'
import {gotoRoute, anchorRoute } from '../../Router'


class DevPlayView {
  init(){    
    document.title = "Dev Play"   
    this.render() 
  }

  render(){
    const template = html`
        <style>
      .grid-lrg {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 0.5em 0.5em;
        grid-auto-flow: row dense;
      }

      .grid-sml {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        grid-gap: 0.5em 0.5em;
        grid-auto-flow: row dense;
      }

      .item {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 0.25em;
        padding: 2em;
      }

      .item-large {
        background: rgba(255, 0, 0, 0.25);
        grid-column: auto / span 2;
        grid-row: auto / span 2;
        /* height: 200px; can add a height if needed to enlarge further*/
      }
      img { height: 100px; }

    </style>
     
      <div id="contentpanelid" class="content-panel">  
        <main-app-header pagename="${document.title}"></main-app-header>
        
        <section id="project-1" class="project-box v-center">
          <div class="h-center work-box"> 
            <div class="grid-sml">

              <div class="item">
                <widget-icon widget="app-calculator">
                  <img class="widget-icon" alt="Calculator icon"  src="/images/widgets/calculator-icon.png">
                </widget-icon>
              </div>
              
              <div class="item">
                <widget-icon widget="app-image-text">
                  <img class="widget-icon" alt="Image to Text Icon"  src="/images/widgets/image-text-widget.png">
                </widget-icon>
              </div>

              <div class="item">
                <widget-icon widget="app-dark-theme">
                  <img class="widget-icon" alt="Dark Theme Icon"  src="/images/widgets/dark-theme-widget.png">
                </widget-icon>
              </div>

              <div class="item">
                <widget-icon widget="app-date-picker">
                  <img class="widget-icon" alt="Date Range picker Icon"  src="/images/widgets/calendar-widget.png">
                </widget-icon>
              </div>

               
              <div class="item item-large">Large 1</div>
              <div class="item item-large">Large 2</div>

              <div class="item">Small 3</div>
              <div class="item">Small 4</div>
              <div class="item">Small 5</div>
              <div class="item">Small 6</div>

              <div class="item">Small 7</div>
              <div class="item">Small 8</div>
              <div class="item">Small 9</div>
              <div class="item">Small 10</div>

              <div class="item">Small 11</div>
              <div class="item">Small 12</div>
              <div class="item">Small 13</div>

            </div>
            <div id="svg-holder">
        
              Some experiments 
              <br><br>
              And more...

            </div>
          </div>
        </section>

      </div> 

    `

    render(template, App.rootEl)
  }
}

export default new DevPlayView()