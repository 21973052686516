[
  { "code": "af", "name": "Afrikaans" },
  { "code": "sq", "name": "Albanian" },
  { "code": "am", "name": "Amharic" },
  { "code": "ar", "name": "Arabic" },
  { "code": "hy", "name": "Armenian" },
  { "code": "bn", "name": "Bengali" },
  { "code": "bs", "name": "Bosnian" },
  { "code": "bg", "name": "Bulgarian" },
  { "code": "zh", "name": "Chinese" },
  { "code": "hr", "name": "Croatian" },
  { "code": "cs", "name": "Czech" },
  { "code": "da", "name": "Danish" },
  { "code": "nl", "name": "Dutch" },
  { "code": "en", "name": "English" },
  { "code": "eo", "name": "Esperanto" },
  { "code": "et", "name": "Estonian" },
  { "code": "fi", "name": "Finnish" },
  { "code": "fr", "name": "French" },
  { "code": "de", "name": "German" },
  { "code": "el", "name": "Greek" },
  { "code": "he", "name": "Hebrew" },
  { "code": "hi", "name": "Hindi" },
  { "code": "hu", "name": "Hungarian" },
  { "code": "in", "name": "Indonesian" },
  { "code": "it", "name": "Italian" },
  { "code": "ja", "name": "Japanese" },
  { "code": "ko", "name": "Korean" },
  { "code": "ms", "name": "Malay" },
  { "code": "ml", "name": "Malayalam" },
  { "code": "ne", "name": "Nepali" },
  { "code": "no", "name": "Norwegian" },
  { "code": "fa", "name": "Persian" },
  { "code": "pl", "name": "Polish" },
  { "code": "pt", "name": "Portuguese" },
  { "code": "pa", "name": "Punjabi" },
  { "code": "ro", "name": "Romanian" },
  { "code": "ru", "name": "Russian" },
  { "code": "sr", "name": "Serbian" },
  { "code": "sk", "name": "Slovak" },
  { "code": "es", "name": "Spanish" },
  { "code": "sw", "name": "Swahili" },
  { "code": "sv", "name": "Swedish" },
  { "code": "tl", "name": "Tagalog" },
  { "code": "ta", "name": "Tamil" },
  { "code": "th", "name": "Thai" },
  { "code": "tr", "name": "Turkish" },
  { "code": "ug", "name": "Uyghur" },
  { "code": "uk", "name": "Ukrainian" },
  { "code": "ur", "name": "Urdu" },
  { "code": "uz", "name": "Uzbek" },
  { "code": "vi", "name": "Vietnamese" }
]