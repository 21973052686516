import {LitElement, html} from 'lit'
import {gotoRoute} from '../Router'


customElements.define('main-app-header', class AppHeader extends LitElement {
  constructor(){
    super()    
    this.attachShadow({ mode: "open" })
  }

  static get properties(){
    return {
      pagename: {
        type: String
      }
    }
  }

  firstUpdated(){
    super.firstUpdated()
    this.navActiveLinks()
  }

  navActiveLinks(){	
    const currentPath = window.location.pathname
    const navLinks = this.shadowRoot.querySelectorAll('.app-side-menu-items a')
    // const mobileStatus = this.isMobile()
    navLinks.forEach(navLink => {
      if(navLink.href.slice(-1) == '#') return
      if(navLink.pathname === currentPath){			
        navLink.classList.add('active')
      } 
      // else {
      //   if(currentPath === '/design' && mobileStatus == false){ 
      //     navLink.style.color = '#fff' 
      //   }
      // }
    })
  }

  // isMobile(){
  //   let viewportWidth = window.innerWidth
  //   if(viewportWidth <= 768){
  //     return true
  //   }else{
  //     return false
  //   }
  // }

  hamburgerClick(){  
    const appMenu = this.shadowRoot.getElementById('ps-drawer')
    appMenu.style.left = "0"
  }
  closeMenuClick(){  
    const appMenu = this.shadowRoot.getElementById('ps-drawer')
    appMenu.style.left = "-100%"
  }
  
  menuClick(e){
    e.preventDefault()
    const currentPath = window.location.pathname
    const pathname = e.target.closest('a').pathname
    if(currentPath == pathname){return}
    else { 
      this.closeMenuClick()
      gotoRoute(pathname)
    }
  }

  render(){    
    return html`
    <style>      
    
      * { box-sizing: border-box; margin: 0px; padding: 0px; }
      .app-header { position: relative; z-index: 99; max-height: 0; }
      #burger-div { width: 19vw; align-items: center;  display: inline-block; 
        padding: 1em; cursor: pointer; position: absolute; }
      #burger-icon { width: 55px;  top: 10px; position: absolute;  left: 10px; 
        background-color: rgba(255, 255, 255, 0.5); padding: 7px; border-radius: 500px; }
      #ps-drawer {
        position: absolute;
        z-index: 1;
        height: 100vh;
        width: 50vw;
        padding: 0 5vw;
        transition: .5s ease-out;
        left: -100%;
      }
      #nav-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        padding:  3vh 3vh;
        width: 100%;
        height: 300px;
      }
      .close-menu {
        width: 25px;
        position: absolute;
        cursor: pointer;
        right: 15px;
        top: 15px;
      }
      .p-logo { width: 120px; margin: 20px -20px ; }
      .app-side-menu-items { width: 80%; }
      .app-side-menu-items img { margin: auto; width: 70px; margin: 3vh 0; } 
      .app-side-menu-items a {
        display: block;
        padding: .5em;
        text-decoration: none;
        font-family: 'Arial';
        font-size: 1.2em;
        color: var(--header-inactive);
        text-align: left;
      } 
      .app-side-menu-items a:hover { color: var(--brand-light); }
      .app-side-menu-items a.active {
        color: var(--header-active);
        border-left: 1px solid var(--header-active);
      }
      .app-side-menu-items a.design { color: white; }

      @media all and (max-width: 768px){   
        #ps-drawer {
          top: 0;
          height: 100vh;
          width: 50vw;
          background-color: white;
          box-shadow: 4px 0px 5px rgba(0,0,0,0.3);
        }
        #nav-wrapper { height: 100vh;}
        .app-header {
          position: fixed;
          z-index: 90;
          max-height: 10px;
          top: 0;
          right: 0;
          left: 0;
        }
        #nav-wrapper { padding:  1vh 3vh; overflow: auto; }
        .mobile-hide { display: none; }
        #drawer-header { width: 100%; margin-top: 20px;}
      }

      @media all and (max-width: 500px){ 
        #ps-drawer { width: 80vw; } 
      }


      @media all and (min-width: 769px){  
        .desktop-hide { display: none; }
        #burger-div { display: none; }
        #title-div { margin-left: 30vw; width: 47vw; align-items: left; justify-content: left;}
        .logo-home { width: 80px;  }
        #ps-drawer {
          position: relative;
          width: 25vw;
          height: 100%;
          bottom: 0;
          left: 0;
          padding: 0;
        }
        .app-header {
          position: relative;
          z-index: 15;
          background-color: pink; 
          max-height: 10px;
        }
      }
    </style>

    <header class="app-header">
      <div id="burger-div" class="desktop-hide" @click="${this.hamburgerClick}" >     
        <svg version="1.1" id="burger-icon" alt="Menu" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="55px" viewBox="0 0 70.375 17.833" enable-background="new 0 0 70.375 17.833" xml:space="preserve">
          <circle fill="#00D1C3" cx="9.508" cy="8.884" r="8.758"/>
          <circle fill="#00D1C3" cx="35.11" cy="8.884" r="8.758"/>
          <circle fill="#00D1C3" cx="60.711" cy="8.884" r="8.758"/>
        </svg>
      </div>
      <div id="ps-drawer">
        <div id="nav-wrapper"> 
          <svg class="close-menu desktop-hide"  @click="${this.closeMenuClick}" alt="close" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="25px" viewBox="0 0 34.25 33.875" enable-background="new 0 0 34.25 33.875" xml:space="preserve">
            <path fill="#00D1C3" d="M22.592,16.938L32.967,6.563c0.715-0.712,1.104-1.661,1.104-2.669c0-1.01-0.39-1.959-1.104-2.673
              c-1.43-1.426-3.918-1.427-5.344,0L17.248,11.596L6.875,1.22c-1.428-1.427-3.918-1.427-5.344,0c-1.475,1.474-1.475,3.87,0,5.343
              l10.373,10.376L1.531,27.314c-0.715,0.713-1.107,1.662-1.107,2.67c0,1.012,0.393,1.959,1.107,2.674c1.426,1.426,3.916,1.426,5.344,0
              l10.373-10.377l10.375,10.377c0.715,0.711,1.662,1.104,2.674,1.104c1.008,0,1.955-0.394,2.67-1.104c1.473-1.476,1.473-3.872,0-5.344
              L22.592,16.938z"/>
          </svg>
          <nav class="app-side-menu-items">
            <svg version="1.1" class="p-logo desktop-hide" alt="peta logo"  id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	              width="64px" height="64px" viewBox="0 0 64 64" enable-background="new 0 0 64 64" xml:space="preserve">
              <rect fill="#FFFFFF" width="64" height="64"/>
              <g>
                <path fill="#07A898" d="M23.03,7.568c5.436-3.53,14.083-4.052,20.827-0.777c7.414,3.601,11.509,10.59,11.084,20.051
                  c-0.594,13.261-8.189,20.05-16.238,22.096c-7.302,1.859-13.485,0.425-16.732-1.764l4.377-6.212
                  c4.659,2.753,11.155,3.529,16.874,1.129c5.085-2.134,7.788-7.254,9.107-14.05c1.412-7.272-1.122-16.11-8.895-20.121
                  C36.867,4.532,30.301,4.107,23.03,7.568z"/>
                <path fill="#00D1C3" d="M17.523,58.966l-7.484-2.26c0,0,3.742-9.673,5.437-17.58
                  c1.694-7.907,2.752-17.368,2.612-22.38c-0.141-5.013-1.2-10.661-1.2-10.661s2.775,6.483,3.248,13.697
                  c0.565,8.614,0.494,14.545-0.141,22.38C19.484,48.452,17.523,58.966,17.523,58.966z"/>
                <path fill="#07A898" d="M43.717,36.798l-1.482-2.755l-4.731,2.755l-3.389-7.133l-7.483,3.953
                  c0,0,0.14-5.365-0.283-9.247c-0.424-3.884-1.765-9.814-1.765-9.814s0.635,6.778,0.565,10.802
                  c-0.072,4.024-0.283,11.296-0.283,11.296l8.332-4.306l3.105,7.695L43.717,36.798z"/>
                <path fill="#00D1C3" d="M24.653,11.38l-0.07,3.177c0,0,4.942-0.07,10.096,2.048
                  c5.154,2.117,9.248,5.577,9.248,5.577l-5.646,4.307l4.015,7.502l-0.767-6.514l6.778-4.73c0,0-4.097-5.154-10.31-7.978
                  C30.231,11.238,24.653,11.38,24.653,11.38z"/>
              </g>
            </svg>


            <a href="/" @click="${this.menuClick}">Home</a>
            <a href="/solutions" @click="${this.menuClick}">Solutions</a>
            <a href="/contact" @click="${this.menuClick}">Contact</a>
          </nav> 
        </div>
      </div>
    </header>
  
    `
  }
})
